import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import PagePrivacy from "../components/PrivacyPage/PrivacyPage"
import { graphql, useStaticQuery } from "gatsby"
import YAMLGlobal from "../content/global.yaml"
import LazyHydrate from "react-lazy-hydration"

const PrivacyPolicyPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      privacy: directusTermsOfUseEng {
        content
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO title={YAMLGlobal.footer.bottomLine.terms} />
      <LazyHydrate ssrOnly>
        <PagePrivacy
          header={YAMLGlobal.footer.bottomLine.terms}
          data={data.privacy.content}
        />
      </LazyHydrate>
    </Layout>
  )
}

export default PrivacyPolicyPage
